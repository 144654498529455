import React, { useState, useEffect, Fragment } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { uploadMutation } from '../queries/queries';
import { REACT_APP_IMAGE_BASE_URL } from '../env';
import passwordeye from '../img/eye-solid.svg';
import { REACT_APP_BASE_URL } from '../env';
import FieldWrapper from './FieldWrapper';

function Form(props) {
  const { children, sContent, get, parent, add, update, _id, onComplete, invitation, invitationAdminEmails } = props;

  const [addMutation, { loading: loadingAdd, error: errorAdd }] = useMutation(add);
  const [updateMutation, { loading: loadingUpdate, error: errorUpdate }] = useMutation(update);

  function submit(e) {
    e.preventDefault();
    const opts = {
      variables: sContent,
      refetchQueries: get && [{ query: get, variables: { [parent]: sContent[parent] } }],
    };
    if (sContent._id) {
      updateMutation(opts)
        .then(result => {
          // Om förening ändrats och inbjudna e-postadresser är ifyllt
          if(result.data.updateAssociation !== undefined && invitationAdminEmails?.invitationEmails?.length > 0) {
            invitationAdminEmails.invitationEmails.forEach(email => {
              invitation({variables: {
                email: email,
                associationId: result.data.updateAssociation._id,
                portalUrlForEmails: `${REACT_APP_BASE_URL}/`
              }})
            })
          }
          onComplete(); 
        })
        .catch(useMutationError => { console.log('fromUpdateError', useMutationError, useMutationError && useMutationError.graphQLErrors) });
    } else {
      // delete opts.variables.adminEmails
      addMutation(opts)
        .then(result => {
          // Om förening lagts till och inbjudna e-postadresser är ifyllt
          if(result.data.createAssociation !== undefined && invitationAdminEmails?.invitationEmails?.length > 0) {
            invitationAdminEmails.invitationEmails.forEach(email => {
              invitation({variables: {
                email: email,
                associationId: result.data.createAssociation._id,
                portalUrlForEmails: `${REACT_APP_BASE_URL}/`
              }})
            })
          }
          onComplete(); 
        })
        .catch(useMutationError => { console.log('formAddError', useMutationError, useMutationError && useMutationError.graphQLErrors) });
    }
  }
  // console.log('loadingAdd', loadingAdd);
  // console.log('loadingUpdate', loadingUpdate);

  return (
    <form onSubmit={(e) => submit(e)}>
      {errorAdd && errorAdd.graphQLErrors.map(({ message }, i) => (
        <p className="error form" key={`addError${i}`}>{message}</p>
      ))}
      {errorUpdate && errorUpdate.graphQLErrors.map(({ message }, i) => (
        <p className="error form" key={`updateError${i}`}>{message}</p>
      ))}
      <button type="button" className="xs cancel" onClick={() => props.onComplete()}>Avbryt</button>
      <button type="submit" className="xs primary">{(sContent && sContent._id) ? 'Spara' : 'Lägg till'}</button>
      {children}
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.any.isRequired,
  sContent: PropTypes.object,
  get: PropTypes.object,
  parent: PropTypes.string,
  add: PropTypes.object.isRequired,
  update: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

Form.defaultProps = {
  sContent: null,
  get: null,
  parent: null,
};

function Group(props) {
  const { children, title } = props;
  return (
    <fieldset className="field-group">
      <legend className="group-title">{title}</legend>
      {children}
    </fieldset>
  );
}

Group.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};


function Field(props) {
  const { name, type, x2, req, title, placeholder, value, onChange, disableWhenEmpty, selectDataInfo, selectData, disabled } = props;
  const [upload, { data: uploadData }] = useMutation(uploadMutation);
  const [inputVal, setInputVal] = useState();
  const [isPasswordShow, setIsPasswordShow] = useState(false)

  useEffect(() => {
    onChange({ [name]: value });
  }, []);

  useEffect(() => {
    //        if (uploadData) uploadData.part_url = 'test/test.jpg';
    if (uploadData && uploadData.singleFileUpload && uploadData.singleFileUpload.partURL) setParentState(uploadData.singleFileUpload.partURL);
  }, [uploadData]);

  function setParentState(v) {
    if (type === 'number' || type === 'select') {
      onChange({ [name]: parseInt(v, 10) });
    } 
    else {
      onChange({ [name]: v });
    }
  }

  function setParentStateSelect(v) {
    setParentState(v.value);
  }

  function setParentStateMultiSelect(selectedValues) {
    const values = [];
    if (selectedValues) {
      selectedValues.map((v) => {
        values.push(v.value);
        return null;
      });
    }
    setParentState(values);
  }


  const values = [];
  let selected = null;
  const components = {
    DropdownIndicator: null,
  };

  switch (type) {
    case 'select':
    case 'selectYesOrNo':
    case 'selectObject':
      if (selectDataInfo && selectData && !selectDataInfo.loading) {
        selectData.map((option) => {
          // console.log(option);
          // const val = returnSelectedObject ? option : id;
          const label = option.name ? option.name : `${option.firstname} ${option.lastname}`;
          values.push({ value: option._id, label });
          if (option._id === value) {
            selected = { value, label };
          }
          return null;
        });
      }

      return (
        <FieldWrapper name={name} title={title} x2={x2}>
          <Select
            className={`${name} ${x2 && 'x2'}`}
            options={values}
            value={selected}
            placeholder={placeholder}
            isLoading={selectDataInfo.loading}
            onChange={setParentStateSelect}
            isDisabled={disableWhenEmpty && values.length === 0}
          />
        </FieldWrapper>
      );

    case 'selectPeriod':
      if (selectDataInfo && selectData && !selectDataInfo.loading) {
        selectData.map((option) => {
          // const val = returnSelectedObject ? option : id;
          const date = new Date(option.startDate);
          const year = date.getFullYear();
          const label = `${year} - ${option.bookletTitle}`;
          values.push({ value: option.period, label });
          if (option.period === value) {
            selected = { value, label };
          }
          return null;
        });
      }

      return (
        <FieldWrapper name={name} title={title} x2={x2}>
          <Select
            className={`${name} ${x2 && 'x2'}`}
            options={values}
            value={selected}
            isLoading={selectDataInfo.loading}
            onChange={setParentStateSelect}
            isDisabled={disableWhenEmpty && values.length === 0}
          />
        </FieldWrapper>
      );

    case 'admins':

      const createOption = (label) => ({
        label,
        value: label,
      });

      function handleChange(v, actionMeta) {
        setParentStateMultiSelect(v);
      }

      function handleKeyDown(event) {
        if (!inputVal) return;
        switch (event.key) {
          case 'Enter':
          case 'Tab':
            event.preventDefault();
            setInputVal('');
            if(value?.invitationEmails) {
              setParentState(value.invitationEmails ? [...value.invitationEmails, inputVal] : [inputVal]);
            } else {
              setParentState(value ? [...value, inputVal] : [inputVal]);
            }
            
        }
      };

      if (value) {
        if(value?.invitationEmails) {
          value?.invitationEmails?.map((option) => {
            values.push({ value: option, label: option });
            return null;
          });
        } else {
          value.map((option) => {
            values.push({ value: option, label: option });
            return null;
          });
        }
      }

      return (
        <FieldWrapper name={name} title={title} x2={x2}>
          <CreatableSelect
            components={components}
            className={`${name}`}
            inputValue={inputVal}
            options={values}
            defaultValue={values}
            isMulti
            menuIsOpen={false}
            onChange={handleChange}
            onInputChange={setInputVal}
            onKeyDown={handleKeyDown}
            placeholder={placeholder || 'Ange en e-postadress och tryck enter'}
            value={values}
          />
        </FieldWrapper>
      );

    case 'image':

      return (
        <FieldWrapper name={name} title={title} x2={x2}>
          <div className="image-upload-container">
            <div alt="image" style={{ backgroundImage: `url(${REACT_APP_IMAGE_BASE_URL}${value})` }} />
            <input
              style={{ position: 'relative' }}
              type="file"
              onChange={(event) => {
                const [file] = event.target.files;
                const fileSize = file.size;
                const maxSize = 1000000;
                console.log('file: ', file);
                if (fileSize > maxSize) {
                  alert('Filen är för stor. Max 1MB');
                  return;
                }
                upload({
                  variables: { file, folder: 'company' },
                });
              }}
            />
          </div>
        </FieldWrapper>
      );

    default:
      return (
        <FieldWrapper name={name} title={title} x2={x2}>
          {type !== 'password'
            ? <input type={type} value={value ? value : ''} onChange={(e) => setParentState(e.target.value)} disabled={disabled && true}/>
            : <Fragment>
              <input type={isPasswordShow ? 'text' : 'password'} value={value ? value : ''} onChange={(e) => setParentState(e.target.value)} />
              {type === 'password' && <div className={`password-icon  ${isPasswordShow && 'showed'}`}  onClick={() => setIsPasswordShow(!isPasswordShow)}><img src={passwordeye} className={`show-password`} /></div>}
            </Fragment>
          }

        </FieldWrapper>
      );
  }
}


Field.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  selectData: PropTypes.array,
  selectDataInfo: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  x2: PropTypes.bool,
  req: PropTypes.bool,
  disableWhenEmpty: PropTypes.bool,
};

Field.defaultProps = {
  x2: false,
  req: false,
  disableWhenEmpty: false,
  value: null,
  placeholder: null,
  selectData: null,
  selectDataInfo: null,
};

function Button(props) {
  const { children, className, loading, disabled, type, onClick } = props;



  return (
    <button className={`${className} ${loading && 'loading'}`} disabled={disabled} type={type} onClick={onClick}>
      <span>{children}</span>
      {loading &&
        <div className="bouncing-container">
          <div className="element-animation one"></div>
          <div className="element-animation two"></div>
          <div className="element-animation three"></div>
        </div>
      }
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  loading: false,
  className: '',
  type: 'button',
  onClick: null,
};

export { Form, Field, Group, Button };
